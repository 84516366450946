const EMPTY_TEXT = 'XXXX'
const MEDIUM_WIDTH = 600
const DOC_MAX_SIZE = 1e7
const SCROLL_OFFSET = 16
const MIN_OCCURRENCES = 4
const LOW_AGREEMENT_RATE = 0.25
const HIGH_AGREEMENT_RATE = 0.5
const SIDEBAR_TOOLTIP_DEBOUNCE = 300

const AI_SYNTHESIS_MODEL = 'gpt-4-turbo-preview'

// URLs
const PRICE_CALCULATION_SHEET = 'https://airtable.com/shrTdZFW8d2yRqt4F'
const AI_SYNTHESIS_CF = 'https://europe-west1-pretto-apis.cloudfunctions.net/ai-synthesis-generator-2'
const AI_SYNTHESIS_FEEDBACK =
  'https://docs.google.com/forms/d/e/1FAIpQLSfA0XNmN2j96KUNue1L-h1VNOlB4YgHOk8siJcf6YkjBQLvmw/viewform'
const AI_CONSULTANCY_CF = 'https://europe-west1-pretto-apis.cloudfunctions.net/finspot-gcf-proxy'
const S3_DOCUMENTS = 'https://s3-eu-central-1.amazonaws.com/docs.pretto.fr/'
const PRIORIZATION_ZAP = 'https://hooks.zapier.com/hooks/catch/2061324/ob2bpj7/'
const AIRTABLE_FEEDBACK_DB = 'appuv7P91pXMHSUeZ'
const AIRTABLE_API_KEY = process.env.VUE_APP_AIRTABLE_API_KEY
const DIGITAL_INSURE_API_BASE_URL = process.env.VUE_APP_DIGITAL_INSURE_API_BASE_URL
const DIGITAL_INSURE_AXLR_ROUTE = '/accelerassur-web/'
const DIGITAL_INSURE_PROJECT_ROUTE = '/#/viewQuotes/quoteshow/'
const BOURSO_REQUEST_TF_URL = `https://pretto-fr.typeform.com/to/Q4o8mKCs`
const STONLY_GUIDE_STEPPED_LOAN =
  'https://stonly.com/guide/fr/comment-mettre-en-place-un-pret-a-paliers-manuellement-LD1jeVl0zD/Steps/'
const CLOUDINARY_URL = 'https://res.cloudinary.com/pretto-fr/image/upload'

// Files
const ALLOWED_FILE_TYPES = ['application/pdf', 'image/jpeg', 'image/png']
const ALLOWED_FILE_EXTENSIONS = ['pdf', 'jpeg', 'jpg', 'png']

const DOCUMENTS_ORDER = [
  'carte_sejour',
  'cni',
  'contrat_mariage',
  'jugement_divorce',
  'etat_liquidatif',
  'livret_famille',
  'justif_domicile',
  'impots',
  'bulletins_salaire',
  'attestation_pole_emploi',
  'contrat',
  'rsi',
  'liasse_fiscale',
  'compte_professionnel',
  'relevé_retraite',
  'status_kbis',
  'releves_compte',
  'releves_compte_dormant',
  'compte_joint',
  'justif_epargne',
  'offre_pret',
  'tableau_amortissement',
  'attestation_hebergement',
  'titre_propriete',
  'bail',
  'decl_primo_accedant',
  'logement_fonction',
  'CNI_hebergeant',
  'contrat_pret',
  'estimation_bien',
  'sci_is',
  'offre_initiale',
  'sci',
  'dommage_ouvrage',
  'contrat_construction',
  'contrat_reservation',
  'devis_travaux',
  'garantie_parfait',
  'garantie_decennale',
  'mandat',
  'permis_construire',
  'promesse',
  'devis_complementaires',
  'baux_locatifs',
  'declaration_locatif',
  'justif_loc',
]

export const useConstants = () => ({
  AI_SYNTHESIS_CF,
  AI_SYNTHESIS_FEEDBACK,
  AI_SYNTHESIS_MODEL,
  AI_CONSULTANCY_CF,
  AIRTABLE_API_KEY,
  AIRTABLE_FEEDBACK_DB,
  ALLOWED_FILE_EXTENSIONS,
  ALLOWED_FILE_TYPES,
  BOURSO_REQUEST_TF_URL,
  CLOUDINARY_URL,
  DIGITAL_INSURE_API_BASE_URL,
  DIGITAL_INSURE_AXLR_ROUTE,
  DIGITAL_INSURE_PROJECT_ROUTE,
  DOC_MAX_SIZE,
  DOCUMENTS_ORDER,
  EMPTY_TEXT,
  HIGH_AGREEMENT_RATE,
  LOW_AGREEMENT_RATE,
  MEDIUM_WIDTH,
  MIN_OCCURRENCES,
  PRICE_CALCULATION_SHEET,
  PRIORIZATION_ZAP,
  SCROLL_OFFSET,
  SIDEBAR_TOOLTIP_DEBOUNCE,
  STONLY_GUIDE_STEPPED_LOAN,
  S3_DOCUMENTS,
})
