import { useMutation, useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import BankRequestApprovalQuery from '@/schemas/Preparation/BankRequestApproval.query.gql'
import CreateInterrogationMutation from '@/schemas/Preparation/CreateInterrogation.mutation.gql'
import DocsDataQuery from '@/schemas/Preparation/DocsData.query.gql'
import DropInterrogationMutation from '@/schemas/Preparation/DropInterrogation.mutation.gql'
import InterrogationPreviewLinkQuery from '@/schemas/Preparation/InterrogationPreviewLink.query.gql'
import InterrogationsQuery from '@/schemas/Preparation/Interrogations.query.gql'
import MandatoryDocsListQuery from '@/schemas/Preparation/MandatoryDocsList.query.gql'
import ReopenInterrogationMutation from '@/schemas/Preparation/ReopenInterrogation.mutation.gql'
import ResubmitInterrogationMutation from '@/schemas/Preparation/ResubmitInterrogation.mutation.gql'
import SetApprovalCommentMutation from '@/schemas/Preparation/SetApprovalComment.mutation.gql'
import UpdateInterrogationResponseMutation from '@/schemas/Preparation/UpdateInterrogationResponse.mutation.gql'

import IBankRequestApprovalQuery from '@/types/schemas/Preparation/BankRequestApprovalQuery.interface'
import ICreateInterrogationMutation from '@/types/schemas/Preparation/CreateInterrogationMutation.interface'
import IDocsDataQuery from '@/types/schemas/Preparation/DocsDataQuery.interface'
import IDropInterrogationMutation from '@/types/schemas/Preparation/DropInterrogationMutation.interface'
import IInterrogation from '@/types/Interrogation.interface'
import IInterrogationBankResponse from '@/types/InterrogationBankResponse.interface'
import IInterrogationsQuery from '@/types/schemas/Preparation/InterrogationsQuery.interface'
import IInterrogationPreviewLinkQuery from '@/types/schemas/Preparation/InterrogationPreviewLinkQuery.interface'
import IMandatoryDoc from '@/types/MandatoryDoc.interface'
import IMandatoryDocsListQuery from '@/types/schemas/Preparation/MandatoryDocsListQuery.interface'
import IReopenInterrogationMutation from '@/types/schemas/Preparation/ReopenInterrogationMutation.interface'
import IResubmitInterrogationMutation from '@/types/schemas/Preparation/ResubmitInterrogationMutation.interface'
import ISetApprovalCommentMutation from '@/types/schemas/Preparation/SetApprovalCommentMutation.interface'
import IUpdateInterrogationResponseMutation from '@/types/schemas/Preparation/UpdateInterrogationResponseMutation.interface'
import { EResponseType } from '@/types/ResponseType.enum'

const { getActiveRequestId, getProjectId } = useServiceHelper()

interface ICreateInterrogationInput {
  bankContactId: string
  relatedProjectIds: string[]
  prequalificationId?: string
}

const createInterrogation = async (
  variables: ICreateInterrogationInput,
): Promise<ICreateInterrogationMutation['createInterrogation'] | undefined> => {
  const { data } = await useMutation<ICreateInterrogationMutation>({
    mutation: CreateInterrogationMutation,
    variables: {
      ...variables,
      projectId: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return data?.createInterrogation
}

const dropInterrogation = async (
  interrogationId: string,
): Promise<IDropInterrogationMutation['dropInterrogation'] | undefined> => {
  const { data } = await useMutation<IDropInterrogationMutation>({
    mutation: DropInterrogationMutation,
    variables: {
      interrogationId,
    },
  })

  return data?.dropInterrogation
}

const getBankRequestApproval = async (
  approvalId: string,
): Promise<IBankRequestApprovalQuery['bankRequestApproval']> => {
  const {
    data: { bankRequestApproval },
  } = await useQuery<IBankRequestApprovalQuery>({
    query: BankRequestApprovalQuery,
    variables: {
      approvalId,
    },
  })

  return bankRequestApproval
}

interface IDocsDataInput {
  interrogationId: string
  transmissionId: string
}

const getDocsData = async (variables: IDocsDataInput): Promise<IDocsDataQuery> => {
  const { data } = await useQuery<IDocsDataQuery>({
    query: DocsDataQuery,
    variables: {
      ...variables,
      id: getProjectId(),
    },
  })

  return data
}

const getInterrogationPreviewLink = async (id: string): Promise<string> => {
  const {
    data: {
      interrogationPreviewLink: { link },
    },
  } = await useQuery<IInterrogationPreviewLinkQuery>({
    query: InterrogationPreviewLinkQuery,
    variables: {
      id,
    },
  })

  return link
}

const getInterrogations = async (): Promise<IInterrogation[]> => {
  const {
    data: { interrogations },
  } = await useQuery<IInterrogationsQuery>({
    query: InterrogationsQuery,
    variables: {
      id: getProjectId(),
    },
  })

  return interrogations
}

const getMandatoryDocsList = async (transmissionId: string): Promise<IMandatoryDoc[]> => {
  const {
    data: { mandatoryDocsList },
  } = await useQuery<IMandatoryDocsListQuery>({
    query: MandatoryDocsListQuery,
    variables: {
      transmissionId,
    },
  })

  return mandatoryDocsList
}

const reopenInterrogation = async (
  interrogationId: string,
): Promise<IReopenInterrogationMutation['reopenInterrogation'] | undefined> => {
  const { data } = await useMutation<IReopenInterrogationMutation>({
    mutation: ReopenInterrogationMutation,
    variables: {
      interrogationId,
    },
  })

  return data?.reopenInterrogation
}

const resubmitInterrogation = async (
  interrogationId: string,
): Promise<IResubmitInterrogationMutation['resubmitInterrogation'] | undefined> => {
  const { data } = await useMutation<IResubmitInterrogationMutation>({
    mutation: ResubmitInterrogationMutation,
    variables: {
      interrogationId,
      requestId: getActiveRequestId(),
    },
  })

  return data?.resubmitInterrogation
}

interface ISetApprovalCommentInput {
  approvalId: string
  senderComment: string
}

const setApprovalComment = async (
  variables: ISetApprovalCommentInput,
): Promise<ISetApprovalCommentMutation['setApprovalComment'] | undefined> => {
  const { data } = await useMutation<ISetApprovalCommentMutation>({
    mutation: SetApprovalCommentMutation,
    variables,
  })

  return data?.setApprovalComment
}

interface IInterrogationResponse {
  interrogationId: string
  bankResponses: Omit<IInterrogationBankResponse, 'id'>[]
  bankResponse: EResponseType | null
  bankResponseRate: number
  bankResponseDuration: number
  bankDeniedReason: string | null
  guarantyResponse: EResponseType | null
  insuranceResponse: EResponseType | null
  clientResponse: EResponseType | null
  clientDeniedReason: string | null
}

const updateInterrogationResponse = async (
  interrogationResponse: IInterrogationResponse,
): Promise<IUpdateInterrogationResponseMutation['updateInterrogationResponse'] | undefined> => {
  const { data } = await useMutation<IUpdateInterrogationResponseMutation>({
    mutation: UpdateInterrogationResponseMutation,
    variables: interrogationResponse,
  })

  return data?.updateInterrogationResponse
}

export const useInterrogationService = () => ({
  createInterrogation,
  dropInterrogation,
  getBankRequestApproval,
  getDocsData,
  getInterrogationPreviewLink,
  getInterrogations,
  getMandatoryDocsList,
  reopenInterrogation,
  resubmitInterrogation,
  setApprovalComment,
  updateInterrogationResponse,
})
