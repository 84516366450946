import { defineComponent as _defineComponent } from 'vue'
import { resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, Suspense as _Suspense, withCtx as _withCtx, Transition as _Transition, unref as _unref, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "bg-gray-300 text-gray-900 min-h-screen" }
const _hoisted_2 = { class: "bg-gray-800 text-white h-screen grid place-content-center text-center relative z-50" }
const _hoisted_3 = { class: "text-xl mb-8" }

import SpinnerIcon from '@/components/icons/SpinnerIcon.vue'

import { useAnalytics } from '@/config/composables/segment'
import { RouterView } from 'vue-router'
import * as Sentry from '@sentry/vue'
import { storeToRefs } from 'pinia'
import { useAuth0 } from '@auth0/auth0-vue'
import { useStonlyHelper } from '@/config/stonly.helpers'
import { useStore } from '@/config/composables/store'


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { user } = useAuth0()
const store = useStore()
const { projectId } = storeToRefs(store)
const { setAdditionalInfo, setUserEmail } = useStonlyHelper()

if (process.env.NODE_ENV === 'production') {
  const { identify } = useAnalytics()

  if (user.value) {
    identify(user.value.email, {
      email: user.value.email,
    })

    Sentry.setUser({
      email: user.value.email,
    })

    setAdditionalInfo({
      user_email: user.value.email!,
    })

    setUserEmail(user.value.email!)
  }

  if (projectId.value) {
    setAdditionalInfo({
      project_id: projectId.value,
    })
  }
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(RouterView), null, {
      default: _withCtx(({ Component }) => [
        _createVNode(_Transition, { name: "fade" }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_Suspense, { timeout: 0 }, {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
              ]),
              fallback: _withCtx(() => [
                _createElementVNode("div", _hoisted_2, [
                  _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t('app_loading')), 1),
                  _createVNode(SpinnerIcon, { class: "w-12 h-12 text-blue-300 m-auto" })
                ])
              ]),
              _: 2
            }, 1024))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ]))
}
}

})