import { useQuery } from '@/plugins/apollo'
import { useServiceHelper } from './service.helper'

import FinancingQuery from '@/schemas/Financing.query.gql'

import IFinancingQuery from '@/types/schemas/FinancingQuery.interface'
import { IFinancingError, IFinancingValid } from '@/types/Financing.interface'

const { getActiveRequestId, getProjectId } = useServiceHelper()

const getFinancing = async (): Promise<IFinancingError | IFinancingValid> => {
  const { data } = await useQuery<IFinancingQuery>({
    query: FinancingQuery,
    variables: {
      id: getProjectId(),
      requestId: getActiveRequestId(),
    },
  })

  return data.financing
}

export const useFinancingService = () => ({
  getFinancing,
})
