import { defineStore } from 'pinia'
import { cloneDeep, uniqBy } from 'lodash'
import { useBankAccountService } from '@/services/bank_account.service'
import { useBankContactService } from '@/services/bank_contact.service'
import { useBankService } from '@/services/bank.service'
import { useBankStatementService } from '@/services/bank_statement.service'
import { useDocumentService } from '@/services/document.service'
import { useFinancingService } from '@/services/financing.service'
import { useGlobalDataService } from '@/services/global_data.service'
import { useInterrogationService } from '@/services/interrogation.service'
import { useNotificationService } from '@/services/notification.service'
import { usePrequalificationService } from '@/services/prequalification.service'

import TFinancing, { EFinancingState } from '@/types/Financing.type'
import TNotification from '@/types/Notification.type'
import IAccount from '@/types/Account.interface'
import IAdvice from '@/types/Advice.interface'
import IBankAccount from '@/types/BankAccount.interface'
import IBankContact from '@/types/BankContact.interface'
import IBankDetails from '@/types/BankDetails.interface'
import IBankStatementsAnalysis from '@/types/BankStatementsAnalysis.interface'
import IBankStatementsAnalysisInput from '@/types/schemas/Preparation/BankStatementsAnalysisInput.interface'
import IBrokerageFees from '@/types/BrokerageFees.interface'
import IDeal from '@/types/Deal.interface'
import IDocument from '@/types/Document.interface'
import IDocumentFile from '@/types/DocumentFile.interface'
import IFileBatch from '@/types/FileBatch.interface'
import IGlobalProject from '@/types/GlobalProject.interface'
import IGlobalRequest from '@/types/GlobalRequest.interface'
import IIncomesCalculationRecommendation from '@/types/IncomesCalculationRecommendation.interface'
import IInterrogation from '@/types/Interrogation.interface'
import IPrequalification from '@/types/Prequalification.interface'
import IScoring from '@/types/Scoring.interface'
import { EBatchStrategyName } from '@/types/BatchStrategyName.enum'
import { EFileClassName } from '@/types/FileClassName.enum'

const { getBankAccounts } = useBankAccountService()
const { getBankContacts } = useBankContactService()
const { getBankDetails, getBankList } = useBankService()
const { getBankStatementsAnalysis } = useBankStatementService()
const { getDocuments, getLastDocNotificationAt } = useDocumentService()
const { getFinancing } = useFinancingService()
const { getGlobalData, getIncomesCalculationRecommendation, getInitialGlobalData, getScoring } = useGlobalDataService()
const { getInterrogations } = useInterrogationService()
const { getNotifications } = useNotificationService()
const { getPrequalifications } = usePrequalificationService()

interface IMainStore {
  accounts: IAccount[]
  advices: IAdvice[]
  areDocumentsLoading: boolean
  bankAccounts: IBankAccount[]
  bankContacts: IBankContact[]
  bankDetails: IBankDetails | null
  bankStatementsAnalysis: IBankStatementsAnalysis[]
  banks: IBankDetails[]
  brokerageFees: IBrokerageFees
  deal: IDeal
  docsCompletion: number
  documents: IDocument[]
  financing: TFinancing
  incomesCalculationRecommendation: IIncomesCalculationRecommendation[]
  interrogations: IInterrogation[]
  isBudgetModeActive: boolean
  prequalifications: IPrequalification[]
  notifications: TNotification[]
  project: IGlobalProject
  projectId: string | null
  scoring: IScoring | null
  selectedBank: IBankDetails | null
  usedFinancing: TFinancing
  requests: IGlobalRequest[]
  activeRequestId: string | null
}

export const useStore = defineStore('main', {
  state: (): IMainStore => {
    return {
      accounts: [],
      advices: [],
      areDocumentsLoading: false,
      bankAccounts: [],
      bankContacts: [],
      bankDetails: null,
      bankStatementsAnalysis: [],
      banks: [],
      brokerageFees: {} as IBrokerageFees,
      deal: {} as IDeal,
      docsCompletion: 0,
      documents: [],
      financing: {} as TFinancing,
      incomesCalculationRecommendation: [],
      interrogations: [],
      isBudgetModeActive: false,
      prequalifications: [],
      notifications: [],
      project: {} as IGlobalProject,
      projectId: null,
      scoring: {} as IScoring,
      selectedBank: null,
      usedFinancing: {} as TFinancing,
      requests: [],
      activeRequestId: null,
    }
  },

  getters: {
    mortgagorsNames(): { value: number; label: string }[] {
      return this.project.profile.mortgagors.map((mortgagor, index) => {
        return {
          value: index,
          label: mortgagor.first_name ? `${mortgagor.first_name} ${mortgagor.last_name}` : mortgagor.last_name,
        }
      })
    },

    mortgagorsCount: (state: IMainStore): number =>
      state.project.profile.mortgagors.filter((mortgagor) => !mortgagor._delete).length,

    isMortgagorsDataEmpty: (state: IMainStore): boolean =>
      state.accounts.length !== state.project.profile.mortgagors.length,
    hasMortgagorAccount:
      (state: IMainStore) =>
      (mortgagorId: string): boolean =>
        state.accounts.some((account) => account.mortgagorId === mortgagorId),

    files: (state: IMainStore): IDocumentFile[] => state.documents.flatMap((doc) => doc.files),

    filteredFiles(state: IMainStore): IDocumentFile[] {
      return state.documents
        .flatMap((doc) => doc.files)
        .filter((file) => {
          if (!file.batch?.id) return true

          const isBatchTreated = file.batch.treatedAt

          return file.className === EFileClassName.Result || !isBatchTreated
        })
    },

    batches: (state: IMainStore): IFileBatch[] =>
      state.documents
        .flatMap((doc) => doc.files)
        .reduce<IFileBatch[]>((list, file) => {
          if (file.batch && !list.find(({ id }) => file.batch!.id === id)) {
            list.push(file.batch)
          }

          return list
        }, []),

    pendingBatches(): string[] {
      return this.batches
        .filter((batch) => batch.strategyName === EBatchStrategyName.TerseaStrategy && !batch.treatedAt)
        .map((batch) => batch.id)
    },

    request: (state: IMainStore): IGlobalRequest => state.requests.find(({ id }) => id === state.activeRequestId)!,
  },

  actions: {
    async fetchInitialGlobalData(): Promise<void> {
      const data = await getInitialGlobalData()

      this.accounts = cloneDeep(data.accounts)
      this.advices = cloneDeep(data.advices)
      this.brokerageFees = structuredClone(data.brokerageFees)
      this.deal = structuredClone(data.deal)

      if ('error' in data.financing) {
        this.financing = {
          ...structuredClone(data.financing),
          state: EFinancingState.Error,
        }
      } else {
        this.financing = {
          ...structuredClone(data.financing),
          state: EFinancingState.Valid,
        }
      }

      this.project = data.project
      this.docsCompletion = data.docsCompletion
      this.activeRequestId = data.requests.find(({ primary }) => primary)?.id ?? data.requests[0].id
      this.requests = data.requests

      this.incomesCalculationRecommendation = await getIncomesCalculationRecommendation()
    },

    async fetchGlobalData(): Promise<void> {
      const data = await getGlobalData()

      this.accounts = cloneDeep(data.accounts)
      this.advices = cloneDeep(data.advices)
      this.brokerageFees = structuredClone(data.brokerageFees)
      this.deal = structuredClone(data.deal)

      if ('error' in data.financing) {
        this.financing = {
          ...structuredClone(data.financing),
          state: EFinancingState.Error,
        }
      } else {
        this.financing = {
          ...structuredClone(data.financing),
          state: EFinancingState.Valid,
        }
      }

      this.project = data.project
      this.scoring = structuredClone(data.scoring)
      this.docsCompletion = data.docsCompletion
      this.incomesCalculationRecommendation = await getIncomesCalculationRecommendation()
    },

    async fetchFinancing(): Promise<void> {
      const financing = await getFinancing()

      if ('error' in financing) {
        this.financing = {
          ...structuredClone(financing),
          state: EFinancingState.Error,
        }
      } else {
        this.financing = {
          ...structuredClone(financing),
          state: EFinancingState.Valid,
        }
      }
    },

    async fetchIncomesCalculationRecommendation(): Promise<void> {
      this.incomesCalculationRecommendation = await getIncomesCalculationRecommendation()
    },

    async fetchScoring(): Promise<void> {
      this.scoring = structuredClone(await getScoring())
    },

    async fetchInterrogations(): Promise<void> {
      this.interrogations = structuredClone(await getInterrogations())
    },

    async fetchPrequalifications(): Promise<void> {
      this.prequalifications = structuredClone(await getPrequalifications())
    },

    async fetchDocuments(): Promise<void> {
      this.areDocumentsLoading = true
      const documents = await getDocuments()

      this.documents = structuredClone(documents)
      this.areDocumentsLoading = false
    },

    async fetchBankList(): Promise<void> {
      const bankList = await getBankList()

      this.banks = structuredClone(bankList.sort((bankA, bankB) => bankA.name.localeCompare(bankB.name)))
    },

    async fetchBankAccounts(bankSlug: string): Promise<void> {
      const bankAccounts = await getBankAccounts(bankSlug)

      this.bankAccounts = uniqBy([...structuredClone(bankAccounts), ...this.bankAccounts], 'id')
    },

    async fetchBankContacts(bankSlug: string): Promise<IBankContact[]> {
      const contacts = await getBankContacts(bankSlug)

      this.bankContacts = uniqBy([...structuredClone(contacts), ...this.bankContacts], 'id')

      return structuredClone(contacts)
    },

    async fetchNotifications(): Promise<void> {
      this.notifications = await getNotifications()
    },

    async fetchLastDocNotificationAt(): Promise<void> {
      this.deal.lastDocNotificationAt = await getLastDocNotificationAt()
    },

    async fetchBankDetails(): Promise<void> {
      if (!this.request?.decisions.bankSlug) return

      const bankDetails = await getBankDetails(this.request.decisions.bankSlug)

      this.bankDetails = structuredClone(bankDetails)
    },

    async fetchBankResult(): Promise<void> {
      if (!this.request?.decisions.bankSlug) return

      const bank = await getBankDetails(this.request.decisions.bankSlug)

      this.selectedBank = structuredClone(bank)
    },

    async fetchBankStatementsAnalysis(): Promise<void> {
      const bankStatementsAnalysis = await getBankStatementsAnalysis()

      this.bankStatementsAnalysis = structuredClone(bankStatementsAnalysis)
    },

    async updateBankStatementsAnalysisCollection(): Promise<void> {
      const bankStatementsAnalysisCollection: IBankStatementsAnalysisInput[] = this.bankStatementsAnalysis.map(
        (bankStatementsAnalysis) => ({
          id: bankStatementsAnalysis.id,
          synthesis: bankStatementsAnalysis.synthesis,
        }),
      )

      await useBankStatementService().updateBankStatementsAnalysisCollection(bankStatementsAnalysisCollection)
    },
  },
})
